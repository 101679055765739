<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-4 text-center py-3 border-right">
          <a
            :href="fbUrl"
            target="_new"
            class="link fb"
            data-toggle="tooltip"
            data-placement="top"
            title="Facebook"
          >
            <i class="font-22 fab fa-facebook-f"></i>
          </a>
        </div>
        <div class="col-4 text-center py-3 border-right">
          <a
            :href="youtubeUrl"
            class="link youtube"
            target="_new"
            data-toggle="tooltip"
            data-placement="top"
            title="Youtube"
          >
            <i class="font-22 fab fa-youtube"></i>
          </a>
        </div>
        <div class="col-4 text-center py-3">
          <a
            href="https://parasdham.org/"
            class="link telegram"
            target="_new"
            data-toggle="tooltip"
            data-placement="top"
            title="Home"
          >
            <i class="font-22 fa fa-home"></i>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      fbUrl: "https://www.facebook.com/parasdhamindia",
      youtubeUrl: "https://www.youtube.com/parasdhamtv"
    };
  }
};
</script>
<style></style>
