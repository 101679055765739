export const ApiMixin = {
    data() {
        return {
            dataURL: process.env.VUE_APP_API_URL,
        };
    },
    methods: {
        /*
           Call API
           ENDPOINT: (string) String after baseurl
           TYPE: (string) get,post,patch,put,delete,update
           data: object
           headers: (string)
           successCallback: function
           failureCallBack: function
         */
        callAPI: function (request) {

            this.$http({
                method: request.type,
                url: this.dataURL + request.endpoint,
                headers: request.headers,
                data: request.data,
            })
                .then((response) => {
                    if(request.sweetalert) {
                        this.$swal({
                            icon: 'success',
                            title: response.data.status,
                            text: response.data.message,
                        });
                    }
                    if(typeof request.successCallback  == 'function'){
                        request.successCallback(response);
                    }
                })
                .catch( () => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Request couldn\'t complete, Please try again'
                    });
                });
        }
    }
}
